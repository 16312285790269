.chartCheckbox {
    margin: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: grey;
    transition: background-color .5s;
    cursor: pointer;
    color: white;
    font-size: 12px;
}

.chartCheckbox--selected {
    background-color: #4292c6;
}

p {
    text-align: left
}