.clearCache-container {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
    padding: 20px;
    margin: 10px;
}

.clearCache-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.clearCache-description {
    margin-bottom: 5px;
}