.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
}

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem {
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}
.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em;
}
.rdrDateDisplayItem input {
  text-align: inherit;
}
.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  flex-direction: row;
}

.rdrMonthsHorizontal {
  justify-content: space-around;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  flex-basis: 14.2857142857%;
  box-sizing: inherit;
  text-align: center;
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  user-select: none;
}

.rdrDay {
  box-sizing: inherit;
  width: 14.2857142857%;
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}
.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  pointer-events: none;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  display: inline-flex;
  user-select: none;
  width: 100%;
}

.rdrStaticRanges {
  display: flex;
  flex-direction: column;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}